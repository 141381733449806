import React from "react"
import { graphql } from "gatsby"
import {
  Navigation,
  Blog,
  Footer,
  Hero,
  Breadcrumb,
} from "../../components/Marketing"
import { Seo } from "../../components/Seo/Seo"

export default ({ data }) => {
  const stats = data.allPoem.group
  const authors = data.allUsers.nodes
    .map(author => {
      const found = stats.find(({ user_id }) => author.id === user_id)

      return {
        ...author,
        count: found ? found.count : 0,
      }
    })
    .filter(({ count }) => count > 0)
    .sort((before, after) => {
      return after.count - before.count
    })

  const breadcrumbs = [
    { label: "Répertoire de haïkus", url: "/repertoire/" },
    { label: "Par auteurs", url: "/repertoire/auteurs/" },
  ]

  return (
    <>
      <Seo
        title={`Répertoire de haïkus par auteurs`}
        description={`La page de répertoire de haïkus par auteurs regroupe l'ensemble des haïkus de la communauté, classés par nombre de haïkus produits.`}
        breadcrumbs={breadcrumbs}
        index={false}
      />
      <Navigation />
      <Hero
        theme={{
          as: "div",
          value: <Breadcrumb items={breadcrumbs} />,
        }}
        title={{
          as: "h1",
          value: (
            <>
              Le répertoire des <span className="text-green-600">haïkus </span>
              de la <span className="text-green-600">communauté </span>
              par auteurs
            </>
          ),
        }}
        description={{
          value: (
            <>
              Le <strong>Temple du Haïku</strong> est un site qui permet à la
              communauté de publier et lire des haïkus. Bien que nous soyons des
              créateurs, nous pensons que nous partageons tous une passion pour
              la poésie et le haïku en général.
              <br />
              C'est pour cela que nous avons mis en place un moyen pour nos
              chers auteurs d'avoir une page dédiée, car bien que nous soyons
              passionné par le haïku, nous pensons qu'il est important de mettre
              en avant également les créateurs de ces contenus.
            </>
          ),
        }}
      />
      <Blog
        title={{
          as: "h3",
          value: (
            <>
              Liste des auteurs <br />
              classés par le nombre de{" "}
              <span className="text-green-600">haïkus</span> produits
            </>
          ),
        }}
        description={
          <>
            Seulement les auteurs ayant publiés un haïku au minimum sont
            référencés ici.
          </>
        }
        articles={authors.map(({ firstName, lastName, id, count }) => ({
          category: "Répertoire",
          url: `/repertoire/auteurs/${id}/`,
          title: firstName + " " + lastName,
          id,
          description: `Le répertoire des haïkus produits par ${firstName} ${lastName}`,
        }))}
      />

      <Footer />
    </>
  )
}

export const query = graphql`
  query ClientGetAllUsersForDirectory {
    allUsers {
      nodes {
        id
        firstName
        lastName
      }
    }
    allPoem {
      group(field: user___id) {
        user_id: fieldValue
        count: totalCount
      }
    }
  }
`
